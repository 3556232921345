export function formatTime(time) {
  const t = new Date(time)
  let y = t.getFullYear()
  let m = t.getMonth() + 1
  let d = t.getDate()
  let h = t.getHours()
  let min = t.getMinutes()
  let s = t.getSeconds()
  m = m > 10 ? m : `0${m}`
  d = d > 10 ? d : `0${d}`
  h = h > 10 ? h : `0${h}`
  min = min > 10 ? min : `0${min}`
  s = s > 10 ? s : `0${s}`
  return `${y}.${m}.${d} ${h}:${min}:${s}`
}