import axios from 'axios';

const service = axios.create({
  timeout: 15000  // 请求超时时间
})

service.interceptors.request.use(config => {
  config.headers.wuyunxin = '520'
  return config;
}, error => {
  throw error
})

export default service;