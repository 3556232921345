<template>
  <div>
    <header>
      <div class="name">wyx的个人网站</div>
      <div class="content">
        <span @click="scrollTo('point')">知识点</span>
        <span @click="scrollTo('example')">案例</span>
        <span @click="scrollTo('about')">关于我</span>
      </div>
    </header>
    <section class="intr">
      <div class="my-info">
        <p>这是一个菜鸡前端的个人网站</p>
        <p>用来记录开发中我所遇到的问题</p>
        <p>也用来收集一些需要的知识点</p>
      </div>
    </section>

    <p class="label" ref="point">
      知识点
    </p>

    <section class="points">
      <div class="item" @click="toPage('1')">
        <p class="title">JS</p>
        <p class="explain">具有函数优先的轻量级，解释型或即时编译型的编程语言，web页面的脚本语言</p>
        <img src="@/assets/image/JS.png" alt="">
      </div>
      <div class="item" @click="toPage('2')">
        <p class="title">CSS</p>
        <p class="explain">层叠样式表,用来表现HTML或XML等文件样式的计算机语言</p>
        <img src="@/assets/image/css.png" alt="">
      </div>
      <div class="item" @click="toPage('3')">
        <p class="title">微信小程序</p>
        <p class="explain">小程序的一种，是一种不需要下载安装即可使用的应用</p>
        <img src="@/assets/image/wxss.png" alt="">
      </div>
      <div class="item" @click="toPage('4')">
        <p class="title">webpack</p>
        <p class="explain">前端资源加载/打包工具。它将根据模块的依赖关系进行静态分析，然后将这些模块按照指定的规则生成对应的静态资源。</p>
        <img src="@/assets/image/webpack.png" alt="">
      </div>
      <div class="item" @click="toPage('5')">
        <p class="title">Vue</p>
        <p class="explain">一套构建用户界面的渐进式框架</p>
        <img src="@/assets/image/Vue.png" alt="">
      </div>
      <div class="item" @click="toPage('6')">
        <p class="title">React</p>
        <p class="explain">用于构建用户界面的 JAVASCRIPT 库</p>
        <img src="@/assets/image/react.png" alt="">
      </div>
      
    </section>

    <p class="label" ref="example">
      案例
    </p>
    <section class="example">
      <div class="item" @click="toChat">
        <img class="back-img" src="https://wyx950827.oss-cn-shenzhen.aliyuncs.com/home/pc/example1.png" alt="">
        <div class="demo">
          <p class="title">无聊的聊天室</p>
          <p class="explain-p1">
            <span>无聊人的聊天室</span>
          </p>
          <p class="explain-p2">
            <span>因为无聊所以写的</span>
          </p>
          <p class="explain-p3">
            <span>写完之后觉得更无聊(￣、￣)</span>
          </p>
        </div>
      </div>
      <div class="item" @click="showNoteLog = true">
        <img class="back-img" src="https://wyx950827.oss-cn-shenzhen.aliyuncs.com/home/pc/example2.jpg" alt="">
        <div class="demo">
          <p class="title">云心录</p>
          <p class="explain-p1">
            <span>无聊人的备忘录</span>
          </p>
          <p class="explain-p2">
            <span>可以记录一些不重要的信息</span>
          </p>
          <p class="explain-p3">
            <span>比如文案、心情和目标等</span>
          </p>
        </div>
      </div>
    </section>

    <section>
      <div class="tips">
        <span>有什么建议可以通过右边的留言按钮进行留言</span>,
        <span>希望能和大家一起共勉</span>
      </div>
      <div class="message">
        <img src="@/assets/image/message.png" alt="留言" @click="toggleModel">
        <span class="text">留言</span>
        <img src="@/assets/image/note-board.png" alt="留言板" @click="toBoard">
        <span class="text">留言板</span>
        <div class="msg-model" :class="showMsgModel ? '' : 'hide-model'">
          <div class="item">
            <span class="label-s">头像</span>
            <div class="img-choose-box">
              <img :src="message.headImg" alt="">
              <div class="change-btn" @click="changeHeadImg">换</div>
            </div>
          </div>
          <div class="item">
            <span class="label-s">昵称</span>
            <input type="text" v-model="message.userName">
          </div>
          <div class="item">
            <span class="label-s">留言</span>
            <textarea name="" id="" cols="30" rows="4" v-model="message.content"></textarea>
          </div>
          <div class="item">
            <span class="label-s">回复方式</span>
            <input type="text" v-model="message.replyWay">
          </div>
          <div class="msg-btns">
            <div @click="cancel">取消</div>
            <div @click="confirm">确认</div>
          </div>
        </div>
      </div>
    </section>

    <p class="label" ref="about">
      关于我
    </p>

    <section class="about-me">
      <div>本人从事前端 <b>4</b> 年，目前在深圳前海上班</div>
      <div>擅长: <b>vue</b>、<b>react</b>、<b>小程序</b>、<b>uni-app</b></div>
      <div>喜欢书籍: 《活着》、《文城》、《云边有个小卖部》等叙事类书籍</div>
      <div>喜欢游戏: 英雄联盟、dnf、跑跑卡丁车</div>
      <div>如果知识点中有不对的地方、对于我的个人案例有好的建议的<br />或者有兴趣合作的可以通过留言联系我(最好添加回复方式)</div>
      <div style="font-weight: bold;">少几分执着，多一份热爱(๑•̌.•̑๑)ˀ̣ˀ</div>

      <!-- <div class="card-box">
        <img src="@/assets/image/card.png" alt="名片" @click="showCard">
        <span>我的名片</span>
      </div> -->
    </section>

    <footer>
      <a data-v-20d470cc="" href="http://www.beian.miit.gov.cn" target="_blank" class="a-bottom">粤ICP备19004771号</a>
    </footer>

    <el-dialog title="小程序二维码" :visible.sync="showNoteLog" width="300px">
      <img src="https://wyx950827.oss-cn-shenzhen.aliyuncs.com/home/pc/xcx.png" style="margin: 0 auto;display: block;" width="200" alt="">
    </el-dialog>
  </div>
</template>

<script>
import { addMessageUrl } from '../assets/js/api';
import { Notification, MessageBox, Dialog } from 'element-ui';

export default {
  components: {
    'el-dialog': Dialog
  },
  data() {
    return {
      showMsgModel: false,
      message: {
        headImg: 'https://wyx950827.oss-cn-shenzhen.aliyuncs.com/headimg/head1.png',
        userName: '',
        content: '',
        replyWay: ''
      },
      showNoteLog: false
    }
  },
  methods: {
    scrollTo(type) {
      window.scrollTo(0, this.$refs[type].offsetTop - 60)
    },
    toPage(type) {
      this.$router.push('/point/' + type)
    },
    toBoard() {
      this.$router.push('/board')
    },
    toChat() {
      window.location.href = 'http://chat.wuyunxin.com'
    },
    toggleModel() {
      this.showMsgModel = !this.showMsgModel
    },
    showCard() {
      console.log('showCard')
    },
    changeHeadImg() {
      this.headIndex = this.headIndex > 11 ? 1 : this.headIndex + 1
      this.message.headImg = 'https://wyx950827.oss-cn-shenzhen.aliyuncs.com/headimg/head' + this.headIndex + '.png';
    },
    confirm() {
      
      if (this.message.userName === '') {
        MessageBox.alert('填写昵称可以方便找到之前的留言')
        return
      }
      if (this.message.content === '') {
        MessageBox.alert('消息不能为空')
        return
      }
      this.$axios.post(addMessageUrl, this.message)
      .then(res => {
        console.log(res)
        this.showMsgModel = false
        Notification.success({
          title: '成功提交',
          message: '回复后可在留言板查看,填写回复方式可以更快收到回复'
        })
      }).catch(() => {
        Notification.error({
          title: '提交失败',
          message: '是不是网络有什么问题'
        })
      })
    },
    cancel() {
      this.showMsgModel = false
      this.message = {
        headImg: 'https://wyx950827.oss-cn-shenzhen.aliyuncs.com/headimg/head1.png',
        userName: '',
        content: '',
        replyWay: ''
      }
    }
  },
  mounted() {
    this.headIndex = 1
  }
}
</script>

<style lang="less" scoped>
header {
  padding: 10px;
  position: sticky;
  top: 0;
  background: #FFFFFF;
  z-index: 3;
}

header {
  display: flex;
}

header .name {
  margin-left: 30px;
}

header .content {
  margin-left: 100px;
}

header .content span {
  margin: 0 50px;
  cursor: pointer;
  position: relative;
}

header .content span:hover::after {
  transform: scaleX(1);
}

header .content span::after {
  content: "";
  transition: .2s;
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 1px;
  background: #000000;
  transform: scaleX(0);
}

.intr {
  height: 600px;
  background: url('https://wyx950827.oss-cn-shenzhen.aliyuncs.com/home/pc/back5.jpg') no-repeat;
  background-position: center center;
  background-size: cover;
}

.intr .my-info {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .3);
  border-top: 1px solid transparent;
}

.intr .my-info p {
  color: #FFFFFF;
  text-align: center;
}

.intr .my-info p:nth-of-type(1) {
  margin-top: 200px;
  font-size: 28px;
}

.intr .my-info p:nth-of-type(2) {
  margin-top: 30px;
}

.intr .my-info p:nth-of-type(3) {
  margin-top: 30px;
}

.points, .example {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0 40px;
}

.points .item, .example .item {
  width: 400px;
  height: 200px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  margin: 50px 0;
  position: relative;
  cursor: pointer;
  transition: .3s;
  overflow: hidden;
}

.points .item:hover {
  background: rgba(0, 0, 0, .05);
}

.points .item .title {
  font-size: 26px;
  padding: 20px;
  letter-spacing: 2px;
}

.points .item .explain {
  font-size: 16px;
  width: 240px;
  margin-left: 20px;
  letter-spacing: 1px;
  line-height: 26px;
}

.points .item img {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 40px;
  bottom: 40px;
}

.label {
  padding: 20px;
  font-size: 26px;
  text-align: center;
  margin: 30px;
}

.example .item .back-img{
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 1.2s;
}

.example .item .demo {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, .1);
  z-index: 2;
  color: #FFFFFF;
  transition: .4s;
}

.example .item .demo .title {
  font-size: 26px;
  margin: 20px;
  letter-spacing: 2px;
}

.example .item .demo span {
  padding: 5px 10px;
  letter-spacing: 1px;
  font-size: 14px;
}

.example .item .demo p {
  margin-left: 20px;
  margin-bottom: 10px;
}

.example .item .demo .explain-p1 {
  transition: .4s;
  transition-delay: .1s;
  transform: translateX(-400px);
}

.example .item .demo .explain-p2 {
  transition: .4s;
  transition-delay: .2s;
  transform: translateX(-400px);
}

.example .item .demo .explain-p3 {
  transition: .4s;
  transition-delay: .3s;
  transform: translateX(-400px);
}

.example .item:hover .demo{
  background: rgba(0, 0, 0, .7);
}

.example .item:hover .back-img {
  transform: scale(1.2);
}

.example .item:hover .explain-p1 {
  transform: translateX(0);
}

.example .item:hover .explain-p2 {
  transform: translateX(0);
}

.example .item:hover .explain-p3 {
  transform: translateX(0);
}

.tips {
  font-size: 22px;
  text-align: center;
  padding: 20px;
  letter-spacing: 1px;
}

.message {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 60px;
  margin: 20px;
  position: relative;

  .text {
    padding-left: 10px;
  }
}

.message img:nth-of-type(1) {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.message img:nth-of-type(2) {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-left: 30px;
}

.hide-model {
  transform: scale(0);
}

.about-me {
  text-align: center;

  div {
    margin-bottom: 20px;
    padding: 0 30px;
  }

  .card-box {
    display: flex;
    justify-content: flex-end;
    padding-right: 80px;
    align-items: center;

    img {
      width: 30px;
      height: 30px;
      cursor: pointer;
      object-fit: contain;
      float: right;
      margin-right: 10px;
    }
  }
}

.message .msg-model {
  position: absolute;
  bottom: 50px;
  right: 200px;
  width: 400px;
  background: #FFFFFF;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  z-index: 3;
  // box-shadow: 0 0 5px 1px #e5e5e5;
  padding-top: 50px;
  transition: .3s;
  transform-origin: 100% 100%;

  .item {
    margin-bottom: 50px;
    display: flex;

    .label-s {
      display: inline-block;
      width: 100px;
      margin-left: 30px;
      vertical-align: top;
      padding-top: 5px;
    }

    input {
      height: 30px;
      width: 200px;
      font-size: 14px;
      padding: 0 10px;
    }

    textarea {
      padding: 10px;
      width: 200px;
      resize: none;
    }

    .img-choose-box {
      width: 250px;
      display: flex;
      align-items: center;

      .change-btn {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: darkcyan;
        color: #FFF;
        text-align: center;
        line-height: 30px;
        display: inline-block;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }

  .msg-btns {
    display: flex;
    margin-bottom: 50px;
    justify-content: space-around;
    padding: 0 40px;

    div {
      padding: 5px 20px;
      cursor: pointer;
      border: 1px solid #333;
      border-radius: 2px;
      transition: .1s;
      user-select: none;

      &:hover {
        background: #000;
        color: #FFF;
      }
    }
  }
}

footer {
  text-align: center;
  padding: 20px;
  font-size: 12px;
}

footer a {
  text-decoration: none;
  color: #000;
}

@media screen and (max-width: 500px) {
  header {
    display: none;
  }

  .points .explain {
    width: 160px !important;
    height: 80px;
    display:-webkit-box;
    -webkit-line-clamp:3;
    -webkit-box-orient:vertical;
    overflow: hidden;
  }

  .message {
    display: none;
  }
}
</style>